import "index.css"
import "syntax-highlighting.css"
import "bridgetown-lit-renderer"

# Example Shoelace components. Mix 'n' match however you like!
import '@shoelace-style/shoelace/dist/components/alert/alert.js'
import '@shoelace-style/shoelace/dist/components/avatar/avatar.js'
import '@shoelace-style/shoelace/dist/components/breadcrumb/breadcrumb.js'
import '@shoelace-style/shoelace/dist/components/breadcrumb-item/breadcrumb-item.js'
import '@shoelace-style/shoelace/dist/components/button/button.js'
import '@shoelace-style/shoelace/dist/components/card/card.js'
import '@shoelace-style/shoelace/dist/components/dialog/dialog.js'
import '@shoelace-style/shoelace/dist/components/icon/icon.js'
import '@shoelace-style/shoelace/dist/components/input/input.js'
import '@shoelace-style/shoelace/dist/components/tag/tag.js'
import "@shoelace-style/shoelace/dist/components/spinner/spinner.js"
import [ register_icon_library  ], from: '@shoelace-style/shoelace/dist/utilities/icon-library.js'
import [ set_animation  ], from: '@shoelace-style/shoelace/dist/utilities/animation-registry.js'


# Use the public icons folder:
import [ setBasePath ], from: "@shoelace-style/shoelace/dist/utilities/base-path.js"
setBasePath("/shoelace-assets")

import "*", as: Turbo, from: "@hotwired/turbo"




import "turbo_transitions"
import hotkeys from "hotkeys-js"
hotkeys "cmd+k,ctrl+k" do |event|
   event.prevent_default()
   document.query_selector("bridgetown-search-form > input").focus()
end


async def import_additional_dependencies()
  await import("bridgetown-quick-search/dist")

  document.query_selector("bridgetown-search-form > input").add_event_listener :keydown do |event|
    console.info(event.key_code)
    if event.key_code == 13
       console.info("13")
      document.query_selector("bridgetown-search-results").show_results_for_query(event.target.value)
    end

    event.target.closest("sl-bar-item").query_selector("kbd").style.display = "none"
  end

end

import_additional_dependencies()

##  Loescht die Ausgabe der Sucheregbnisse
document.add_event_listener "turbo:load" do
    search = document.query_selector("bridgetown-search-results")
      search.show_results = false
      search.results = []
      nav = document.query_selector("body > nav")

      menu_hide nav.query_selector("sl-button[menutoggle]")

      nav.query_selector_all("a").each do |item|
        item.remove_attribute "aria-current"
      end
end
window.add_event_listener "turbo:load" do
  document.query_selector_all("sl-button").each do |button|
    button.add_event_listener "click" do |e|

      e.current_target.closest('.modal').query_selector('sl-dialog').show()

      if e.target.local_name != "a"
        anchor = e.current_target.query_selector("a")
        anchor.click() if anchor
      end
    end
  end
end


register_icon_library('remini',
  resolver: -> name do
    match = name.match(/^(.*?)\/(.*?)(-(fill))?$/)
    match[1] = match[1].char_at(0).upcase() + match[1].slice(1)
    "/images/icons/#{match[1]}/#{match[2]}#{match[3] || '-line'}.svg"
  end,
  mutator: -> svg { svg.set_attribute('fill', 'currentColor')  }
)



import "@fortawesome/fontawesome-free/css/all.min.css"
# Import all JavaScript & CSS files from src/_components
#To opt into `.global.css` & `.lit.css` nomenclature, change the `css` extension below to `global.css`.
# Read https://www.bridgetownrb.com/docs/components/lit#sidecar-css-files for documentation.

import components from "bridgetownComponents/**/*.{js,jsx,js.rb,css}"


import "navbar.js"
console.info("Bridgetown is loaded!")


